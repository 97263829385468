<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div v-if="$i18n.locale === 'en'">
          <div class="content">
            <h4>
              Confidentiality Policy for rportal.aero mobile applications and portal
            </h4>
            <p>
              This application is served and provided by Aerogroup, LLC
              (hereinafter the “Rightholder”), with its office at: 31A Leningradsky prospect, bld. 1, fl. 22, office I, aprt. 3B, 9, 10, 11, 20, Moscow, 125284, Russia. If you install this application or use the
              aircraft portal, we will collect some data on you. This
              Confidentiality Policy contains data on the way the data collected
              by our applications and sites are used, as well as whom you may
              contact in case of any questions or doubts.
            </p>
            <p>
              If you have obtained access to this application via a third party
              site, such party may also collect data on you. In this case you
              shall read and understand the terms of use and the confidentiality
              policy of the software and the site of this party before
              installation and use of the application in order to know how the
              collected data will be used.
            </p>
            <p>
              By installing the application on his/her device or using the
              application in any way, as well as using the rportal.aero portal, the
              User expresses his/her full and unconditional consent to all the
              terms of the License, as well as to processing of his/her personal
              data, specified in the License and Confidentiality Policy of the
              rportal.aero Program and Portal, by the Rightholder.
            </p>
            <ul class="number">
              <li>
                What kind of data do we collect?
                <p>
                  If you want to register with us, we may ask you to provide
                  data, such as the name, user name, e-mail address, postal
                  address, age, gender, telephone number and data on the
                  product. On our sites and in our applications we may conduct
                  additional interviews where you will be asked to express your
                  opinion on various products and services. If you download any
                  materials from our sites or via our applications, we will
                  automatically collect data on your device. If you use
                  community tools, we will be able to save materials and
                  messages received and sent by you; we also reserve the right
                  to monitor your massages. We may also save data on your
                  actions and personal preferences when you visit our sites or
                  use our applications (see the "Cookies" section below).
                </p>

                <p>
                  Before you provide us with someone's personal data (e. g.,
                  within the "invite your friend" campaign), obtain their
                  permissions. Be careful that data provided by you are accurate
                  and up-to-date and timely update such data in case of any
                  changes.
                </p>
              </li>
              <li>
                What do we do with collected data?<br /><br />
                We use collected data for the following purposes:<br />
                <ul class="dashed">
                  <li>
                    to support the operation of our sites and applications, as
                    well as to provide you with information and services,
                    including, but not limited to, downloaded materials, chats,
                    forums, promotional events and competitions;
                  </li>
                  <li>to provide tech support to you;</li>
                  <li>
                    for the purpose of internal analysis and research in order
                    to improve our sites, products and services;
                  </li>
                  <li>
                    to provide you with news and marketing information on
                    products and services of the rportal.aero solution, as well as
                    proposals, products and services of selected third party
                    companies, but only to the extent permitted by applicable
                    laws or specified by you (see the "How to unsubscribe"
                    section below);
                  </li>
                  <li>
                    to send administrative messages to you (e. g., in case you
                    forgot the password);
                  </li>
                  <li>
                    to detect and prevent any fraud and abuse of our sites,
                    applications or services.
                  </li>
                </ul>
              </li>
              <li>
                Cookies
                <p>
                  Some of our sites and applications use cookie files. These are
                  small text files, which are sent by the site to your computer
                  in order to record your actions on the net. Such files may be
                  used by our sites and applications to save your preferences
                  when using our sites and applications in order to improve some
                  aspects of our sites and applications, to record your actions
                  on the net, as well as to provide you with individualized ads
                  and other materials. You may disable the use of cookies in the
                  browser parameters menu, but in this case some functions of
                  our sites and applications may become inaccessible.
                </p>
              </li>
              <li>
                Security
                <p>
                  We take commercially reasonable measures to protect your
                  personal data. The measures include processes and procedures
                  aimed at reducing the risk of unauthorized access to your data
                  or disclosure of the same. However, we do not guarantee the
                  complete exclusion of abuse of your personal data by
                  violators. Keep passwords for your accounts in a safe place
                  and do not disclose them to third parties. If you get to know
                  of unauthorized use of your password or any other security
                  violation, contact us immediately.
                </p>
                <p>
                  Forums, chats and other spheres of the gamer community
                  accessible by means of our sites and applications are public.
                  You should not disclose information which allows to identify
                  you or any other person in such spheres. We do not bear any
                  responsibility for the security and protection of data
                  disclosed by you in such spheres.
                </p>
              </li>
              <li>
                How to unsubscribe
                <p>
                  If you do not want to receive our marketing messages any more,
                  follow the link available in any of such messages or visit the
                  registration section of our site and change parameters of
                  message reception. If you are not registered with any of our
                  sites, send a message with the "Unsubscribe" (without
                  quotation marks) subject to: unsubscribe@rportal.aero.
                </p>
              </li>
              <li>
                Contact information
                <p>
                  For general questions contact the help desk using the details
                  published on the site: http://rportal.aero
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <div class="content">
            <h4>
              Политика конфиденциальности для мобильных приложений и портала
              rportal.aero
            </h4>
            <p>
              Данное приложение обслуживается и предоставляется ООО «Аэрогрупп»,
              (далее «Правообладатель»), офис которого расположен по адресу:
              Россия, 125284, г. Москва, вн. тер. г. Муниципальный округ Беговой, пр-кт Ленинградский, д. 31А стр. 1, этаж/помещ./ком. 22/I/3Б,9,10,11,20. Если вы установите и будете использовать
              данное приложение или пользоваться бортовым порталом, мы будем
              собирать некоторые данные о вас. В настоящей Политике
              конфиденциальности приводятся сведения о том, как именно
              используются данные, собранные нашими приложениями и сайтами, а
              также о том, куда вы можете обратиться в случае каких-либо
              вопросов или сомнений.
            </p>
            <p>
              Если вы получили доступ к данному приложению через сайт третьего
              лица, таковое лицо также может собирать данные о вас. В этом
              случае вам следует ознакомиться с условиями использования и
              политикой конфиденциальности программного обеспечения и сайта
              данного лица перед установкой и использованием приложения, чтобы
              узнать о том, как будут использоваться собранные данные.
            </p>
            <p>
              Устанавливая приложение на свое устройство или используя
              приложение любым образом, а также используя портал rportal.aero
              Пользователь выражает свое полное и безоговорочное согласие со
              всеми условиями Лицензии, а также на обработку Правообладателем
              его персональных данных, указанных в Лицензии и настоящей Политики
              конфиденциальности Программы и Портала rportal.aero
            </p>
            <ul class="number">
              <li>
                Какие виды данных мы собираем?
                <p>
                  Если вы захотите зарегистрироваться у нас, мы можем попросить
                  вас предоставить такие данные, как имя, имя пользователя,
                  адрес электронной почты, почтовый адрес, возраст, пол, номер
                  телефона и сведения о продукте. На наших сайтах и в наших
                  приложениях могут проводиться дополнительные опросы, в ходе
                  которых вам будет предложено выразить свое мнение о различных
                  продуктах и услугах. Если вы загрузите какие-либо материалы с
                  наших сайтов или посредством наших приложений, мы
                  автоматически получим сведения о вашем оборудовании. Если вы
                  воспользуетесь инструментами сообщества, у нас будет
                  возможность сохранять принимаемые и отправляемые вами
                  материалы и сообщения; также мы оставляем за собой право
                  осуществлять мониторинг ваших сообщений. Также мы можем
                  сохранять сведения о ваших действиях и личных предпочтениях
                  при посещении наших сайтов или использовании наших приложений
                  (см. раздел «Файлы cookie» ниже).
                </p>

                <p>
                  Перед тем как передавать нам персональные данные любых других
                  лиц (к примеру, в рамках акций «пригласи друга»), заручитесь
                  их согласием. Следите за тем, чтобы предоставляемые вами
                  данные были точны и актуальны, и своевременно обновляйте их в
                  случае изменений.
                </p>
              </li>
              <li>
                Что мы делаем с собранными данными?<br /><br />
                Мы используем собранные данные в следующих целях: <br />
                <ul class="dashed">
                  <li>
                    для обеспечения функционирования наших сайтов и приложений,
                    а также предоставления вам информации и услуг, включая, без
                    ограничения, загружаемые материалы, чаты, форумы, рекламные
                    акции и конкурсы;
                  </li>
                  <li>для оказания вам услуг технической поддержки;</li>
                  <li>
                    для внутреннего анализа и исследований в целях улучшения
                    наших сайтов, а также других продуктов и услуг;
                  </li>
                  <li>
                    для отправки вам новостей и маркетинговой информации о
                    продуктах и услугах решения rportal.aero, а также предложениях,
                    продуктах и услугах избранных сторонних компаний, но только
                    в пределах, разрешенных действующим законодательством или
                    указанных вами (см. раздел «Как отказаться от подписки»
                    ниже);
                  </li>
                  <li>
                    для отправки вам административных сообщений (к примеру, в
                    случае, если вы забыли пароль);
                  </li>
                  <li>
                    для обнаружения и предотвращения случаев мошенничества и
                    злоупотребления нашими сайтами, приложениями или услугами.
                  </li>
                </ul>
              </li>
              <li>
                Файлы cookie
                <p>
                  Некоторые из наших сайтов и приложений используют файлы
                  cookie. Это небольшие текстовые файлы, которые сайт отправляет
                  на ваш компьютер с целью записи ваших действий в сети. Такие
                  файлы могут использоваться нашими сайтами и приложениями для
                  сохранения ваших предпочтений при пользовании нашими сайтами и
                  приложениями, для улучшения некоторых аспектов работы наших
                  сайтов и приложений, для записи ваших действий в сети, а также
                  для предоставления вам персонализированной рекламы и других
                  материалов. Вы можете отключить использование файлов cookie в
                  меню параметров браузера, однако в этом случае некоторые
                  функции наших сайтов или приложений могут быть недоступны.
                </p>
              </li>
              <li>
                Безопасность
                <p>
                  Мы принимаем коммерчески оправданные меры по защите ваших
                  персональных данных. В число этих мер входят процессы и
                  процедуры, направленные на снижение рисков
                  несанкционированного доступа к вашим данным или разглашения
                  таковых. Однако мы не гарантируем полного исключения
                  злоупотреблений вашими персональными данными со стороны
                  нарушителей. Храните пароли для ваших учетных записей в
                  безопасном месте и не разглашайте их третьим лицам. Если вам
                  станет известно о несанкционированном использовании вашего
                  пароля или ином нарушении безопасности, немедленно свяжитесь с
                  нами.
                </p>
                <p>
                  Форумы, чаты и другие области сообщества игроков, доступные с
                  помощью наших сайтов и приложений, являются публичными. В
                  таких областях не следует разглашать информацию, позволяющую
                  установить вашу личность или личность любого другого лица. Мы
                  не несем ответственности за безопасность и защиту данных,
                  разглашенных вами в таких областях.
                </p>
              </li>
              <li>
                Как отказаться от подписки
                <p>
                  Если вы больше не хотите получать от нас маркетинговые
                  сообщения, перейдите по ссылке, которая приводится в любом из
                  таких сообщений, либо посетите регистрационный раздел нашего
                  сайта и измените параметры получения сообщений. Если вы не
                  регистрировались ни на одном из наших сайтов, отправьте
                  сообщение с темой «Unsubscribe» (без кавычек) по адресу
                  unsubscribe@rportal.aero.
                </p>
              </li>
              <li>
                Контактная информация
                <p>
                  По общим вопросам обращайтесь в службу поддержки
                  пользователей, реквизиты которой опубликованы на сайте
                  http://rportal.aero
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  title() {
    return this.$t("pages.privacy");
  }
};
</script>

<style scoped></style>
